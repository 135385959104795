<template>
    <div class="app-container">
        <el-card class="box-card">
            <div slot="header" style="display: flex;justify-content: space-between;">
                <span style="line-height: 32px;font-size: 18px;font-weight: bold;">设备基本信息</span>
                <el-button type="primary" @click="handleExport">导出列表</el-button>
            </div>
            <el-row style="line-height: 40px;">
                <el-col :span="12">设备编码：{{deviceInfo.code || '-'}}</el-col>
                <el-col :span="12">设备名称：{{deviceInfo.name || '-'}}</el-col>
                <el-col :span="12" v-if="deviceInfo.companyId">所属公司：{{companyFormatter(deviceInfo.companyId)}}</el-col>
                <el-col :span="12">设备状态：{{deviceInfo.statusName || '-'}}</el-col>
                <el-col :span="12">区域：{{deviceInfo.locationDesc || '-'}}</el-col>
                <el-col :span="12">位置：{{deviceInfo.address || '-'}}</el-col>
                <el-col :span="12">坐标： 经度 {{deviceInfo.longitude || '-'}}，纬度 {{deviceInfo.latitude || '-'}}</el-col>
                <el-col :span="12">A相传感器地址：{{deviceInfo.phaseA || '-'}}</el-col>
                <el-col :span="12">报警阈值：{{deviceInfo.limitA || '-'}}</el-col>
                <el-col :span="12">B相传感器地址：{{deviceInfo.phaseB || '-'}}</el-col>
                <el-col :span="12">报警阈值：{{deviceInfo.limitB || '-'}}</el-col>
                <el-col :span="12">C相传感器地址：{{deviceInfo.phaseC || '-'}}</el-col>
                <el-col :span="12">报警阈值：{{deviceInfo.limitC || '-'}}</el-col>
            </el-row>
        </el-card>
        <el-card class="box-card" style="margin-top: 10px;">
            <div slot="header">
                <span style="line-height: 32px;font-size: 18px;font-weight: bold;">温度监测</span>
            </div>
            <div style="font-size: 12px;color:#999;margin-bottom: 20px;" v-if="historyList.length > 0">
                最后一次更新时间：{{historyList[0]['logTimeStr']}}
            </div>
            <el-table :data="tempList" border style="width: 100%">
                <el-table-column prop="rowName" align="center" label="" width="100px"></el-table-column>
                <el-table-column prop="phaseA" align="center" label="A相"></el-table-column>
                <el-table-column prop="phaseB" align="center" label="B相"></el-table-column>
                <el-table-column prop="phaseC" align="center" label="C相"></el-table-column>
            </el-table>
        </el-card>
        <el-card class="box-card" style="margin-top: 10px;">
            <div slot="header">
                <span style="line-height: 32px;font-size: 18px;font-weight: bold;">历史温度明细</span>
            </div>
            <el-table :data="historyList" border style="width: 100%" v-loading="historyLoading">
                <el-table-column prop="logTime" align="center" label="日期" width="200px"></el-table-column>
                <el-table-column prop="logA" align="center" label="A相"></el-table-column>
                <el-table-column prop="logB" align="center" label="B相"></el-table-column>
                <el-table-column prop="logC" align="center" label="C相"></el-table-column>
            </el-table>
            <el-pagination background layout="total, prev, pager, next, jumper" :page-size="20" :total="total"
                           :current-page="page"
                           @current-change="currentChange" style="margin: 20px 0;"/>
        </el-card>
        <el-card class="box-card" style="margin-top: 10px;">
            <div slot="header">
                <span style="line-height: 32px;font-size: 18px;font-weight: bold;">历史局放明细</span>
                <span style="
                    margin-left: 100px;
                    font-size: 16px;
                    /* font-weight: bold; */
                ">时间范围:</span>
                <el-date-picker
                    v-model="dateArr1"
                    @change="changeDate1"
                    type="monthrange"
                    range-separator="至"
                    start-placeholder="开始月份"
                    end-placeholder="结束月份">
                </el-date-picker>
            </div>
            <el-table :data="historyList1" border style="width: 100%" v-loading="historyLoading1">
                <el-table-column prop="logTime" align="center" label="日期" width="200px"></el-table-column>
                <el-table-column prop="logData" align="center" label="局放"></el-table-column>
            </el-table>
            <el-pagination background layout="total, prev, pager, next, jumper" :page-size="20" :total="total"
                           :current-page="page"
                           @current-change="currentChange" style="margin: 20px 0;"/>
        </el-card>
    </div>
</template>

<script>
import {exportDeviceDetailApi, getDeviceDetailApi, getDeviceSearchBaseApi, getTemperaturePageApi
    , getDischargePageApi} from '@/api'

export default {
        name: 'deviceView',
        data() {
            return {
                id: this.$route.params.id,
                historyLoading: false,
                historyLoading1: false,
                baseInfo: {
                    statuList: [],
                    regionList: [],
                    companyList: []
                },
                deviceInfo: {},
                tempList: [],
                historyList: [],
                historyList1: [],
                page: 1,
                total: 0,
                page1: 1,
                total1: 0,
                dateArr1: [new Date('2021-01-01 00:00:00'), new Date()]
            }
        },
        mounted() {
            this.getBaseInfo()
            this.getView()
            this.getTemp()
            this.getDischarge()
        },
        methods: {
            getBaseInfo() {
                getDeviceSearchBaseApi().then(res => {
                    if (res) {
                        this.baseInfo = res.data
                    }
                })
            },
            getView() {
                getDeviceDetailApi({deviceId: this.id}).then(res => {
                    if (res) {
                        this.deviceInfo = res.data.deviceBase
                        this.tempList = res.data.sensorInfo
                    }
                })
            },
            getTemp() {
                this.historyLoading = true
                getTemperaturePageApi({
                    deviceId: this.id,
                    pageNum: this.page
                }).then(res => {
                    if (res) {
                        console.log(res)
                        this.historyList = res.data.list
                        this.total = res.data.totalRow
                    }
                    this.historyLoading = false
                })
            },
            changeDate1(){
                this.page1 = 1
                this.getDischarge()
            },
            getDischarge() {
                this.historyLoading1 = true
                getDischargePageApi({
                    deviceId: this.id,
                    pageNum: this.page1,
                    startTime: this.formattedDate(this.dateArr1[0]),
                    endTime: this.formattedDate(this.dateArr1[1]),
                }).then(res => {
                    if (res) {
                        console.log(res)
                        this.historyList1 = res.data.list
                        this.total1 = res.data.totalRow
                    }
                    this.historyLoading1 = false
                })
            },
            formattedDate(date){
                const year = date.getFullYear();  
                const month = (date.getMonth() + 1).toString().padStart(2, '0');  
                const day = date.getDate().toString().padStart(2, '0');  
                const formattedDate = `${year}-${month}-${day}`;  
                return formattedDate
            },
            currentChange(page) {
                this.page = page
                this.getTemp()
            },
            handleExport() {
                exportDeviceDetailApi({
                    deviceId: this.id
                }).then(res => {
                    if (res) {
                        const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = '设备详情数据导出'
                        link.click()
                    }
                })
            },
            companyFormatter(companyId) {
                return this.baseInfo.companyList.length > 0 ? this.baseInfo.companyList.filter(item => item.id === companyId)[0]['name'] : '-'
            }
        }
    }
</script>

<style scoped>

</style>
